import React from 'react'
import styles from './UpButton.module.scss'
import {useEffect, useState} from "react";
import ArrowUp from "@/Atoms/Icons/ArrowUp";

export default function(){


  const [showUp, setShowUp] = useState(false)
  const handleScroll = (e: Event) => {

    const target = e.target as Document
    const scrollDistance = target.documentElement.scrollTop
    const scrollLimit = 200
    if (scrollDistance > scrollLimit && !showUp){
      setShowUp(true)

      document.body.classList.toggle('up-button', true)
    }
    if (scrollDistance <= scrollLimit){
      setShowUp(false)
      document.body.classList.toggle('up-button', false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  return <a
    className={`${styles.upButton} ${showUp && styles.showUpButton}`}
    href={'#'}
    title={'Terug naar boven'}
  >
<ArrowUp />
  </a>
}
